import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import WorkDetail from "../../components/workdetail"
import vitasVideo from "../../assets/vitas.mp4"

import ReactPlayer from "react-player"

import { playerWrapper169, reactPlayer } from "./vitas.module.css"

const query = graphql`
  {
    allFile(filter: { name: { eq: "VitaS" } }) {
      nodes {
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
`

const VitaS = () => {
  const data = useStaticQuery(query)
  const vitasImage = data.allFile.nodes[0].childImageSharp.gatsbyImageData

  return (
    <Layout>
      <Seo title="VitaS" />
      <WorkDetail
        company="VitaS"
        image={vitasImage}
        description="A project based on helping residential care facilities to attract more employees by conducting research and delivering a promotional video."
        brief="One of the largest and most interesting projects we did for our own pop-up agency Boots On The Moon was for VitaS, a group of residential care facilities. They were struggling to find motivated employees and asked us for help. "
        solution="We started by interviewing current employees of VitaS and of their competition. In addition, we send out a survey that was filled in by more than 150 people. Based on the results that came out of this, we came up with a creative concept that consisted of copywriting, social media templates, designs of stickers and a promotional video."
      />
      <div className={playerWrapper169}>
        <ReactPlayer
          url={vitasVideo}
          controls={true}
          width="100%"
          height="100%"
          pip={true}
          className={reactPlayer}
        />
      </div>
    </Layout>
  )
}

export default VitaS
